<template>
  <div
   class="footer row bg-color"
   data-aos-easing="linear"
   >
    <!-- :data-aos-offset="0" data-aos="fade-up" -->

    <div class="row d-flex justify-content-center mx-auto px-0">
      <div class="col-xl-2 col-12 item py-lg-0 pb-4 mb-2 mb-lg-4 mx-4">
        <div class="footer-brand">
          <router-link to="/">
            <img
              :src="
              $i18n.locale==='zh-TW'?
              require('@/assets/1920/1920_header_logo.svg'):
              require('@/assets/1920/1920_header_logo_en.svg')"
              alt="footer-logo"
              class="logo-img"
            />
          </router-link>
        </div>
        <div class="my-4 footer-brand" v-if="innerWidth>1200">
          <span class="mx-2 img-link" v-on:click="clickLink('https://www.facebook.com/leuco.sapphire.shipping/')">
            <img src="~@/assets/1920/1920_footer_icon1.svg" alt="" />
          </span>
          <!-- <a class="mx-2 img-link" href="mailto:Service@leucoship.com">
            <img src="~@/assets/1920/1920_footer_icon2.svg" alt="" />
          </a> -->
          <span class="mx-2 img-link" v-on:click="clickLink('https://line.me/ti/p/@728eztol')">
            <img src="~@/assets/1920/1920_footer_icon3.svg" alt="" />
          </span>
        </div>
      </div>
      <!-- 網站導航 -->
      <div class="w-footer-col col-xl-1 col-md-2 col-12 item text my-lg-0 my-1 text-footer-align px-0 px-md-2 mx-3">
        <h3 class="text-nowrap" v-on:click="extendTreeView(1)">{{ $t("web-nav") }}</h3>
        <transition name="fade">
        <ul class="padding-extender" v-if="treeviewArray[1].collapse" :key="2">
          <li class="my-1">
            <router-link
              to="/#news"
            >
              {{ $t("route-news") }}
            </router-link>
          </li>
          <li class="my-1">
            <router-link
              to="/introduce-leucosapphire"
            >
              {{ $t("route-leucosapphire") }}
            </router-link>
          </li>
          <li class="my-1">
            <router-link
              to="/explore-liuqiu"
            >
              {{ $t("route-explore-liuqiu") }}
            </router-link>
          </li>
          <li class="my-1">
            <router-link
              to="/shop/order/search"
            >
              {{ $t("route-booking-search") }}
            </router-link>
          </li>
          <li class="my-1">
            <router-link
              to="/q-a"
            >
              {{ $t("route-qa") }}
            </router-link>
          </li>
          <li class="my-1">
            <router-link
              to="/pdpa"
            >
              {{ $t("route-pdpa") }}
            </router-link>
          </li>
        </ul>
        </transition>
      </div>
      <!-- 手機取票 -->
      <div class="w-footer-col col-xl-1 col-md-2 col-12 item text my-lg-0 my-1 text-footer-align px-0 px-md-2 mx-3 min-width-fit-content">
        <h3 class="text-nowrap" v-on:click="extendTreeView(2)">{{ $t("online-ticket-title") }}</h3>
        <transition name="fade">
        <ul class="padding-extender" v-if="treeviewArray[2].collapse" :key="3">
          <li class="my-1">
            <a :href="getEnv('VUE_APP_ONLINE_TICKET_WEB_URL')+'/d'" target="_blank">{{getEnv('VUE_APP_ONLINE_TICKET_WEB_URL')+'/d'}}</a><!--{{ getEnv('VUE_APP_ONLINE_TICKET_WEB_URL') }}-->
          </li>
          <li>
            <img v-if="innerWidth>768" src="@/assets/1920/1920_online_ticket_qr.svg"  alt="">
            <img v-else src="@/assets/768/768_online_ticket_qr.svg" alt="">
          </li>
        </ul>
        </transition>
      </div>
      <!-- APP訂票 -->
      <div class="w-footer-col col-xl-1 col-md-2 col-12 item text my-lg-0 my-1 text-footer-align px-0 px-md-2 mx-3 min-width-fit-content">
        <h3 class="text-nowrap" v-on:click="extendTreeView(3)">{{ $t("App-download-title") }}</h3>
        <transition name="fade">
        <ul class="padding-extender" v-if="treeviewArray[3].collapse" :key="3">
          <li class="my-1">
            <a :href="getEnv('VUE_APP_APP_DOWNLOAD_WEB_URL')" target="_blank">{{getEnv('VUE_APP_APP_DOWNLOAD_WEB_URL')}}</a><!--{{ getEnv('VUE_APP_APP_DOWNLOAD_WEB_URL') }}-->
          </li>
          <li>
            <img v-if="innerWidth>768" src="@/assets/1920/1920_app_download_qr.svg" alt="">
            <img v-else src="@/assets/768/768_app_download_qr.svg" alt="">
          </li>
        </ul>
        </transition>
      </div>

      <!-- 聯絡我們 -->
      <div class="w-footer-col col-xl-2 col-md-4 col-12 item my-lg-0 my-1 text-footer-align px-0 px-md-2 mx-3 "
       >
        <h3 class="text-nowrap" v-on:click="extendTreeView(0)">{{ $t("contact-us") }}</h3>
        <transition name="fade">

        <ul class="px-md-0 px-4 padding-extender contact" v-if="treeviewArray[0].collapse" :key="0">
          <!-- <li class="">
           <div
             class="text-nowrap contact-min-width"
             :class="{
               'zh-tw': $i18n.locale==='zh-TW',
               'en': $i18n.locale==='en',
              }"
            >{{ $t("contact-us-service-24hr-title") }}
            </div>
            <span class="mx-2">|</span>
            <div class="text-start">{{ $t("contact-us-service-24hr-phone") }}</div>
          </li> -->
          <li class="">
            <div
             class="text-nowrap contact-min-width "
             :class="{
               'zh-tw': $i18n.locale==='zh-TW',
               'en': $i18n.locale==='en',
              }"
            >{{ $t("contact-us-office-title") }}</div>
            <span class="mx-2">|</span>
            <div class="text-start">{{ $t("contact-us-office-phone") }}<br>
            </div>
          </li>
          <li class="">
            <div
             class="text-nowrap contact-min-width "
             :class="{
               'zh-tw': $i18n.locale==='zh-TW',
               'en': $i18n.locale==='en',
              }"
            >{{ $t("contact-us-office-work-hr-title") }}</div>
            <span class="mx-2">|</span>
            <div class="text-start">
            <div class="text-pre-wrap">{{ $t("contact-us-office-work-hr") }}</div>
            </div>
          </li>
          <li class="">
            <div
             class="text-nowrap contact-min-width "
             :class="{
               'zh-tw': $i18n.locale==='zh-TW',
               'en': $i18n.locale==='en',
              }"
             >{{ $t("contact-us-address-title") }}</div>
            <span class="mx-2">|</span>
            <div class="text-start">{{ $t("contact-us-address") }}</div>
          </li>
          <li class="">
            <div
             class="text-nowrap contact-min-width"
             :class="{
               'zh-tw': $i18n.locale==='zh-TW',
               'en': $i18n.locale==='en',
              }"
            >{{ $t("contact-us-line-title") }}
            </div>
            <span class="mx-2">|</span>
            <div class="text-start">@728eztol</div>
          </li>
        </ul>

        </transition>
      </div>

      <!-- 語言切換 -->
      <div class="w-footer-col col-xl-1 col-lg-1 col-md-2 col-12 item text my-lg-0 my-1 text-footer-align px-0 px-md-2 mx-3">
        <h3 class="text-nowrap" v-on:click="extendTreeView(4)">{{ $t("language-title") }}</h3>
        <transition name="fade">
        <ul class="locales-footer padding-extender" v-if="treeviewArray[4].collapse" :key="4">
          <li class="my-1 mx-4 mx-md-1">
            <div
              v-on:click="ChangeLanguage('zh-TW')"
              class="language"
              :class="{ active: $i18n.locale === 'zh-TW' ? true : false }"
            >
              中文
            </div>
          </li>
          <li class="my-1 mx-4 mx-md-1">
            <div
              v-on:click="ChangeLanguage('en')"
              class="language"
              :class="{ active: $i18n.locale === 'en' ? true : false }"
            >
              English
            </div>
          </li>
        </ul>
        </transition>
      </div>
    </div>
    <!-- <div class="locales-footer d-flex align-items-center justify-content-center my-2">
      <div
        v-on:click="ChangeLanguage('zh-TW')"
        class="language"
        :class="{ active: $i18n.locale === 'zh-TW' ? true : false }"
      >
        中文
      </div>
      <div class="px-2">|</div>
      <div
        v-on:click="ChangeLanguage('en')"
        class="language"
        :class="{ active: $i18n.locale === 'en' ? true : false }"
      >
        English
      </div>
    </div> -->
    <div class="my-2 footer-brand" v-if="innerWidth<1200">
      <span class="mx-2 img-link" v-on:click="clickLink('https://www.facebook.com/leuco.sapphire.shipping/')">
        <img src="~@/assets/1920/1920_footer_icon1.svg" alt="" />
      </span>
      <!-- <a class="mx-2 img-link" href="mailto:Service@leucoship.com">
        <img src="~@/assets/1920/1920_footer_icon2.svg" alt="" />
      </a> -->
      <span class="mx-2 img-link" v-on:click="clickLink('https://line.me/ti/p/@728eztol')">
        <img src="~@/assets/1920/1920_footer_icon3.svg" alt="" />
      </span>
    </div>
    <p class="copyright">{{ $t("copyright") }}</p>
  </div>
</template>
<script>
import getEnv from '@/utils/env.js'
export default {
  data() {
    return {
      treeviewArray: [
        { collapse: true },
        { collapse: true },
        { collapse: true },
        { collapse: true },
        { collapse: true },
        { collapse: true }
      ],
    }
  },
  watch: {
    'innerWidth': function() {
      if ( innerWidth <= 768 ) {
        this.extendTreeView(-1);
      }
      else {
        this.treeviewArray = [
          { collapse: true },
          { collapse: true },
          { collapse: true },
          { collapse: true },
          { collapse: true },
          { collapse: true }
        ]
      }
    }
  },
  methods: {
    clickLink( url ) {
        window.open(url);
    },
    getEnv( str ) {
      return getEnv(str);
    },
    extendTreeView( index ) {
      if ( this.innerWidth <= 768 ) {
        for ( let i = 0 ; i < this.treeviewArray.length ; i++ ) {
          if ( index === i )
            this.treeviewArray[i].collapse = !this.treeviewArray[i].collapse;
          else
            this.treeviewArray[i].collapse = false;
        }
      }
      else {
        this.treeviewArray[index].collapse = !this.treeviewArray[index].collapse;
      }
      // console.log(this.treeviewArray);
    }
  },
  mounted() {
    this.resizeEventHandler();
  }
};
</script>
<i18n>
{
    "en": {
        "contact-us": "+ Contact Us",
        "contact-us-office-title": "Office",
        "contact-us-office-phone": "+886-8-833-4168",
        "contact-us-office-work-hr-title": "Open",
        "contact-us-office-work-hr": "AM 7:00 - PM 5:00",
        "contact-us-address-title": "Address",
        "contact-us-address": "2F., Building A, No. 43, Chaolong Rd., Donggang Township, Pingtung County 928",
        "contact-us-line-title": "Line@",
        "follow-us": "FOLLOW US",
        "working-hours": "+ WORKING HOURS",
        "working-hours-p": "Monday - Sunday\nAM 6:30-PM 5:00",
        "online-ticket-title": "+ Ticket",
        "App-download-title": "+ App Download",
        "language-title": "+ Language",
        "copyright": "COPYRIGHT © LEUCO SAPPHIRE SHIPPING",
        "web-nav": "+ Navigation"
    },
    "zh-TW": {
        "contact-us": "+ 聯絡我們",
        "contact-us-office-title": "業務電話",
        "contact-us-office-phone": "(08)-833-4168",
        "contact-us-office-work-hr-title": "行政時間",
        "contact-us-office-work-hr": "每日早上7點至下午5點",
        "contact-us-address-title": "公司地址",
        "contact-us-address": "928屏東縣東港鎮朝隆路43號A棟2樓",
        "contact-us-line-title": "加入Line",
        "follow-us": "訂閱我們",
        "working-hours": "+ 營業時間",
        "working-hours-p": "每週一至每週日\nAM 6:30-PM 5:00",
        "online-ticket-title": "+ 手機取票",
        "App-download-title": "+ 線上訂票APP下載",
        "language-title": "+ 語言切換",
        "copyright": "COPYRIGHT © LEUCO SAPPHIRE SHIPPING 版權所有",
        "web-nav": "+ 網站導航"
    }
}
</i18n>
<style scope>
@import "./style/footer.css";
</style>
