<template>
  <div
    class="shipping-panel-block"
    :class="{
      en: $i18n.locale === 'en',
      'zh-TW': $i18n.locale === 'zh-TW',
    }"
  >
    <div class="panel-header">
      <div class="title">
        <!-- <span>{{date.today[0]}}/{{date.today[1]}}</span> -->
        {{ $t("shipping-panel-block-title") }}
      </div>
      <div class="sub-title fw-normal">
        {{ $t("holiday-add-ship-notice") }}
      </div>
    </div>
    <hr />
    <div class="shipping-list">
      <div class="one-route">
        <div class="route-name">
          {{ $t("donggang") }}<span class="arrow"></span>{{ $t("liuqiu") }}
        </div>
        <!-- api時刻表 -->
        <!-- <div
         v-for="(arrayItem, arrayOutIndex) in shiptimeSplitTwoCol(shippingList.outbound)"
         :key="'out'+arrayOutIndex"
         class="ship-time-row">
          <div
            v-for="(item, index) in arrayItem"
            :key="index"
            class="py-1 ship-time d-flex justify-content-sm-center justify-content-start mx-auto"
          >
            {{ item.time }}
            <span
              class="ms-1 text-main-color fw-bold text-nowrap special"
              v-if="item.type === 'special' || item.type === 'speical'"
              >({{ $t("add") }})
            </span>
          </div>
        </div> -->

        <!-- 新時刻表 -->
         <div
         :key="'out'+arrayOutIndex"
         class="ship-time-row">
          <div
            v-for="(item, index) in newShipTime"
            :key="index"
            class="py-1 ship-time d-flex justify-content-sm-center justify-content-start mx-auto"
          >
            {{ item }}
            <!-- <span
              class="ms-1 text-main-color fw-bold text-nowrap special"
              v-if="item.type === 'special' || item.type === 'speical'"
              >({{ $t("add") }})
            </span> -->
          </div>
        </div>
        <div  class="text-main-color fw-bold">{{ dateString }}</div>
         <!-- <div v-else class="text-main-color fw-bold">本表僅供 {{date.today[0]}}/{{date.today[1]}} 參考</div> -->
      </div>
      <hr />
      <div class="one-route">
        <div class="route-name">
          {{ $t("liuqiu") }}<span class="arrow"></span>{{ $t("donggang") }}
        </div>
        <!-- api時刻表 -->
        <!-- <div
         v-for="(arrayItem, arrayInIndex) in shiptimeSplitTwoCol(shippingList.inbound)"
         :key="'in'+arrayInIndex"
         class="ship-time-row">
          <div
            class="py-1 ship-time d-flex justify-content-sm-center justify-content-start mx-auto"
            v-for="(item, index) in arrayItem"
            :key="index"
          >
            {{ item.time }}
            <span
              class="ms-1 text-main-color fw-bold text-nowrap special"
              v-if="item.type === 'special' || item.type === 'speical'"
              >({{ $t("add") }})
            </span>
          </div>
        </div> -->

         <!-- 新時刻表 -->
         <div
         :key="'in'+arrayInIndex"
         class="ship-time-row">
          <div
            class="py-1 ship-time d-flex justify-content-sm-center justify-content-start mx-auto"
            v-for="(item, index) in newShipTimeBack"
            :key="index"
          >
            {{ item}}
            <span
              class="ms-1 text-main-color fw-bold text-nowrap special"
              v-if="item.type === 'special' || item.type === 'speical'"
              >({{ $t("add") }})
            </span>
          </div>
        </div>
      </div>
    </div>
    <hr />
    <div class="panel-footer">
      <div class="d-flex justify-content-center text">
        {{ $t("actual-schedule-desc") }}
      </div>
    </div>
  </div>
</template>
<script>
import { apiGetSailings } from "@/apis/booking.js";

export default {
  data() {
    return {
      date: {
        year: "",
        md: "",
        day: "",
        time: "00:00",
        today:""
      },
      shippingList: {
        outbound: [],
        inbound: [],
      },
      newShipTime:["07:20", "08:50", "10:10", "11:50", "13:30", "15:20", "16:50"],
      newShipTimeBack:["08:10", "09:30", "11:10", "13:00", "14:20","16:00", "17:20"],

    };
  },
  methods: {
    startSetInterval() {
      window.setInterval(() => this.setTime(), 1000);
    },
    setTime() {
      let getDate = this.getToday();
      let getTime = this.getNowTimeHMS();
      this.date.year = getDate.year;
      this.date.md = getDate.md;
      this.date.day = getDate.day;
      this.date.time = getTime;
      this.date.today = getDate.md.split("-")

    },
    async getRouteShippingList(date, routeCode) {
      let data = {
        date: date,
        route_code: routeCode,
      };

      let searchResult = await apiGetSailings(data);
      console.log(searchResult);
      if (searchResult.status === 200) {
        return searchResult.data;
      } else {
        console.log("fail", searchResult);
        return [];
      }
    },
    async getShipTimeTable() {
      let date = new Date();
      this.shippingList.outbound = await this.getRouteShippingList(date, "DL");
      this.shippingList.inbound = await this.getRouteShippingList(date, "LD");
    },
    shiptimeSplitTwoCol(array) {
      if ( array.length > 4 ) {
        let resultArray1 = [] ;
        for ( let i = 0 ; i < Math.floor(array.length/2) ; i++ ) {
          resultArray1.push(array[i]) ;
        }
        let resultArray2 = [] ;
        for ( let i = Math.floor(array.length/2) ; i < array.length ; i++ ) {
          resultArray2.push(array[i]) ;
        }
        console.log([resultArray1, resultArray2]);
        return [resultArray1, resultArray2];
      }
      else {
        return [array] ;
      }
    },
  },
  async mounted() {
    this.resizeEventHandler();
    await this.getShipTimeTable();
    this.setTime();
    this.startSetInterval();
  },
  computed:{
    dateString(){
      let string = ''
      this.$i18n.locale === 'en' ?  string = `This timetable is for reference only` : string = `本表僅供參考`
      return string
    }
  }
};
</script>
<i18n>
{
    "en": {
        "add": "Extra",
        "shipping-panel-block-title": "Regular Timetable",
        "holiday-add-ship-notice": "(Flexible adding ship on holidays)",
        "actual-schedule-desc": "The actual schedule is based on on-site schedule"
    },
    "zh-TW": {
        "add": "加開",
        "shipping-panel-block-title": "固定船班時刻表",
        "holiday-add-ship-notice": "(例假日機動加開航班)",
        "actual-schedule-desc": "實際航班表依現場為主"

    }
}
</i18n>
<style scope>
@import "./style/shipping-panel-block.css";
</style>
